const Routes = {
  login: 'login',
  register: 'register',
  registerComplete: 'register-complete',
  forgotPassword: 'forgot-password',
  changePassword: 'change-password',
  home: 'home',
  accountChangePassword: 'account/change-password',
  userPreferences: 'account/user-preferences',
  accountDetails: 'account/details',
  help: 'help',

  student: 'student',
  addStudent: 'student/add',
  updateStudent: 'student/:studentKey',

  walletTopUp: 'wallet-topup',
  autoTopUp: 'wallet-topup/setup-auto-topup',
  defaultPaymentMethod: 'default-payment-method',
  redirectToApp: 'redirect-to-app',
  accountHistory: 'account-history',
  accountClose: 'account-close',
  orders: 'orders',
  orderTiles: 'order-tiles',
  openOrders: 'open-orders/:studentKey',
  orderNow: 'order-now',
  buzzPost: 'post/:webUrl',
  viewBuzzPost: 'view-post/:buzzPostId',
  newOrder: 'new-order/:studentId/:supplierServiceId',
  news: 'news',
  orderPad: 'order-pad',
  impersonate: 'impersonate',
  logout: 'logout',

  flxSignUp: 'flx-signup/:studentKey',
  flxSignUpSelectStudent: 'flx-signup',
  flxAccountHistory: 'flx-account-history/:flxAccountKey/:from?/:to?',
  flxAccounts: 'student/:studentKey/flx-accounts',
  flxSpendAccount: 'student/:studentKey/flx-accounts/spend',
  flxSavingsAccount: 'student/:studentKey/flx-accounts/savings',
  flxEarnAccount: 'student/:studentKey/flx-accounts/earn',
  flxWelcome: 'flx-welcome/:studentKey',
  flxAppSignUp: 'flx-app-signup/:studentKey',
  flxReferralCode: 'flx-referral-code/:referralCode',
  flxReferral: 'flx-referral',
  flxReplaceCardWithNewDesign: ':studentKey/replace-flx-card-with-new-design',

  mobileAppAuthenticator: 'app-authenticator',
  nativeProfile: 'native/profile-screen',
  networkErrorPage: 'network-error-page',
} as const;

export default Routes;
